<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData;
        "
      >
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :rules="searchRule"
          label-width="70px"
          :model="formInline"
          class="demo-form-inline"
          style="text-align: left"
        >
          <div class="search_box_title">
            {{ $t("searchModule.Query_Table") }}
          </div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Road_name')">
                <el-input
                  v-model="formInline.parkName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('searchModule.date')"
                prop="dateType"
                class="time_items"
              >
                <el-select
                  v-model.trim="formInline.dateType"
                  filterable
                  size="15"
                  style="width: 72px !important"
                  @change="getTimeNow"
                >
                  <el-option label="日" value="day">日</el-option>
                  <el-option label="月" value="month">月</el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="time_item">
                <el-date-picker
                  :picker-options="pickerOptions"
                  @change="timeChange"
                  v-model="timeRangeDefaultTime"
                  :type="
                    formInline.dateType == 'day' ? 'daterange' : 'monthrange'
                  "
                  :valueFormat="'yyyy-MM-dd HH:mm:ss'"
                  range-separator="至"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <!-- <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >查询
              </AuthorityComponent> -->
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  searchData();
                  pageNum = 1;
                "
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB20">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <!--        <div class="pagerWrapper">-->
        <!--          <div class="block">-->
        <!--            <el-pagination v-if="total != 0" @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">-->
        <!--            </el-pagination>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
// import timeRangePick from "@/components/timePicker"
import { dateFormat } from "../../common/js/public.js";
export default {
  name: "carManager",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 20) {
        callback(new Error("最多输十一个字符"));
      }
    };
    const start = new Date();
    const end = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    end.setTime(end.getTime());
    return {
      // 禁用选择未来日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      timeRangeDefaultTime: [start, end],
      modelvalue: "",
      dialogVisible: false,
      parkDetail: [],
      rowData: [],
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      searchRule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
        },
        {
          prop: "exceptCount",
          label: this.$t("list.Number_of_exceptions"),
        },
      ],
      tableData: [],
      formInline: {
        parkName: "",
        dateType: "day",
        startTime: dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
        endTime: dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
      },
    };
  },
  methods: {
    // 默认时间
    getTimeNow() {
      let startTime = new Date();
      let endTime = new Date();
      if (this.formInline.dateType == "day") {
        startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
        endTime.setTime(endTime.getTime() - 3600 * 1000);
        this.formInline.startTime = dateFormat(
          startTime,
          "yyyy-MM-dd  00:00:00"
        );
        this.formInline.endTime = dateFormat(endTime, "yyyy-MM-dd 23:59:59");
      } else {
        let start = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        );
        let lastDay = new Date(
          endTime.getFullYear(),
          endTime.getMonth() + 1,
          0
        );
        this.formInline.startTime = dateFormat(start, "yyyy-MM-dd 00:00:00");
        this.formInline.endTime = dateFormat(lastDay, "yyyy-MM-dd 23:59:59");
      }
      this.timeRangeDefaultTime = [
        dateFormat(startTime, "yyyy-MM-dd 00:00:00"),
        dateFormat(endTime, "yyyy-MM-dd 23:59:59"),
      ];
      this.searchData();
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = dateFormat(
        new Date(timeArr[1]),
        "yyyy-MM-dd 23:59:59"
      );

      if (this.formInline.dateType !== "day") {
        let lastDay = new Date(
          new Date(timeArr[1]).getFullYear(),
          new Date(timeArr[1]).getMonth() + 1,
          0
        );
        this.formInline.endTime = dateFormat(lastDay, "yyyy-MM-dd 23:59:59");
      }
    },
    parkQuerySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
            dataSource: 1,
            areaIds: this.streetId ? this.streetId : this.areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 车牌号
    querySearchAsync(queryString, cb) {
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      const opt = {
        method: "post",
        url: "/acb/2.0/except/countList",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          parkName: this.formInline.parkName,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
        },
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        success: (res) => {
          this.loading = false;
          this.tableData = res.value.exceptReportList;
          // this.total = res.value.total * 1 || 0
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 进入详情页
    toDetail(row) {
      this.$router.push({
        path: "/abnormalAlarmDetail",
        query: {
          carId: row.carId,
          memberId: row.memberId,
          isAuth: row.parkName === 1 ? "true" : "false",
        },
      });
    },
  },
  components: {
    // timeRangePick
  },
  activated() {
    this.searchData();
  },
  mounted() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
::v-deep .el-form-item.time_items {
  min-width: 0;

  .el-input__inner {
    min-width: 72px;
  }
}

::v-deep .el-form-item.time_item {
  .el-date-editor.el-range-editor {
    min-width: 280px;

    .el-range-input {
      min-width: 90px;
    }

    .el-range-separator {
      min-width: 30px;
    }
  }
}

.el-icon-date-custom {
  position: relative;
  left: -26px;
}

.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .chartsWrapper {
    height: 360px;
    margin-bottom: 20px;

    .wrapper {
      background: #FFFFFF;
      border: 1px solid #C0CCDA;
      border-radius: 4px;

      .title {
        font-size: 14px;
        color: #475669;
        padding-left: 17px;
        height: 50px;
        line-height: 50px;
      }
    }
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  >>>.el-input-group__append {
    padding: 0;
  }
}

::v-deep .el-select {
  width: 100% !important;
}
</style>
